















































































































.mobile-audio-player {
  height: auto !important;
  .play-container {
    height: 29px;
    margin-top: 25px;
  }
  .controls {
    margin-top: 0px !important;
    margin-left: 0px;
    /* margin-right: 38px; */
    padding-left: 0px !important;
    justify-content: unset !important;
  }
}
.no-spacing {
  padding-left: 3px !important;
  margin-top: 18px !important;
}
.file-name {
  font-size: 11px;
  position: absolute;
  margin-top: -45px;
}
svg {
  height: 100%;
}
.audio-player {
  height: 40px;
  width: 100%;
  font-family: arial;
  color: black;
  font-size: 0.75em;
  display: flex;
  overflow: hidden;
  .timeline {
    background: #6E7A82;
    height: 7px;
    border-radius: 7px;
    width: 100%;
    position: relative;
    cursor: pointer;
    .progress {
      background: #3fae29;
      width: 0%;
      height: 100%;
      transition: 0.25s;
    }
  }
  .controls {
    margin-top: 17px;
    width: 100%;
    display: flex;
    /* padding: 0 20px; */
    padding-left: 20px;
    flex-direction: column;
    justify-content: center;
    > * {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .time {
    display: flex;
    display: flex;
    justify-content: space-between;
    > * {
      padding: 2px;
    }
  }
}
