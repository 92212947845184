































































































.mp3-recorder {
  width: 100%;
  max-width: 250px;
  margin-top: 6px;
  > div {
    gap: 20px;
  }
}
.recording-text {
  color: #db5706;
}
